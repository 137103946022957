import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/"
        }}>{`Introduction`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/designing-state"
        }}>{`Designing State`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/quick-start"
        }}>{`Quick Start`}</a></li>
    </ul>
    <p><strong parentName="p">{`API`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/design"
        }}>{`Design`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/docs/api/design/state-nodes"
            }}>{`State Nodes`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/docs/api/design/event-handlers"
            }}>{`Event Handlers`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/state"
        }}>{`State`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/createState"
        }}><inlineCode parentName="a">{`createState`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/createDesign"
        }}><inlineCode parentName="a">{`createDesign`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/useStateDesigner"
        }}><inlineCode parentName="a">{`useStateDesigner`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/useSelector"
        }}><inlineCode parentName="a">{`useSelector`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/createSelectorHook"
        }}><inlineCode parentName="a">{`createSelectorHook`}</inlineCode></a></li>
    </ul>
    <p><strong parentName="p">{`Usage`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/usage/react"
        }}>{`React`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/usage/javascript"
        }}>{`JavaScript`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/usage/typescript"
        }}>{`TypeScript`}</a></li>
    </ul>
    {
      /* **Learn**
      - [Intro](/docs/learn/intro)
      - [State](/docs/learn/state)
      - [Events](/docs/learn/events)
      - [Handlers](/docs/learn/event-handlers)
      - [States](/docs/learn/states)
      - [Transitions](/docs/learn/transitions)
      - [Data](/docs/learn/data)
      - [Values](/docs/learn/values)
      - [Actions](/docs/learn/actions)
      - [Conditions](/docs/learn/conditions)
      - [Results](/docs/learn/results)
      - [Transition Events](/docs/learn/transition-events)
      - [Repeat Events](/docs/learn/repeat-events)
      - [Async Events](/docs/learn/async-events) */
    }
    <p><strong parentName="p">{`Examples`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/counter"
        }}>{`Counter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/toggle"
        }}>{`Toggle`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/input"
        }}>{`Input`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/todo"
        }}>{`Todo`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/todos"
        }}>{`Todos`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/stopwatch"
        }}>{`Stopwatch`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/timer"
        }}>{`Timer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/calculator"
        }}>{`Calculator`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/drawing"
        }}>{`Drawing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/tiles"
        }}>{`Tiles`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/dogs"
        }}>{`Dogs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/breakout"
        }}>{`Breakout`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/snake"
        }}>{`Snake`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/examples/tetris"
        }}>{`Tetris`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
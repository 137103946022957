import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GithubIcon = makeShortcode("GithubIcon");
const TwitterIcon = makeShortcode("TwitterIcon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/steveruizok/state-designer"
        }}><GithubIcon mdxType="GithubIcon" /></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/steveruizok"
        }}><TwitterIcon mdxType="TwitterIcon" /></a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      